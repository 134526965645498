@import url('https://fonts.googleapis.com/css2?family=Cardo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');



:root{
--papaya-txt-1: #ffdca2;

--papaya-txt-2: #ffc86f;

--papaya-txt-3: #ffc86f;

--papaya-txt-4: #ffb53c;

--papaya-txt-5: #ffa109;

--papaya-bg-1: #ffdca2;

--papaya-bg-2: #ffc86f;

--papaya-bg-3: #ffc86f;

--papaya-bg-4: #ffb53c;

--papaya-bg-5: #ffa109;
}



@media (max-width: 1921px) {
	.myLink{
		color: black;
		font-size: 18px;
		text-decoration: none;
		text-decoration-line: none;
		font-family: 'Montserrat', sans-serif;
	}

	.cardWidth {
		width: 240px;
	}	

	.cardProduct {
		min-width: calc( 15vw + 4px);
		max-width: calc( 15vw + 4px);
		width: calc( 15vw + 2px);
		min-height: 302px;
		max-height: 302px;
		height: 302px;
		cursor: pointer;
		margin: 4px;
		border: 2px solid black;
		padding: 0px;
		display: flex;
		justify-content: center;
		align-items: center
	}

	.cardImage {
		min-width:  15vw;
		max-width:  15vw;
		width:  15vw;
		min-height: 230px;
		height: 230px;
		max-height: 230px;
		object-fit: cover;
		border-radius: 3px 3px 0px 0px;
		margin: 0px;
		padding: 0px;

	}

	.cardTitle {

		font-size: 12px;
		min-width: 13vw;
		max-width: 13vw;
		width: 13vw;
		height: 2.5em;
		padding-right: 2px;
		margin: 0px;
		overflow: hidden;



	}

	.cardBody {
			border-top: 1px solid black;
		min-width: 15vw;
		max-width:  15vw;
		width: 42vw;
		background-color: papayawhip;
		padding: 8px;
		margin: 0px;
		border-radius: 0px 0px 3px 3px;
	}

}

/*
@media (max-width: 1025px) {
	.myLink{
		color: black;
		font-size: 18px;
		text-decoration: none;
		text-decoration-line: none;
		font-family: 'Montserrat', sans-serif;
	}

	.cardWidth {
		width: 240px;
	}	

}

@media (max-width: 769px) {
	.fullWindowWidth {
		width: calc(100vw - var(--scrollbar-width))
	}

	.homeBorder{
		border-style: solid;
		border-color: black;
		border-width: 15px;
	}
	.myLink{
		color: black;
		font-size: 20px;
		text-decoration: none;
		text-decoration-line: none;
		font-family: 'Montserrat', sans-serif;
	}

	.cardWidth {
		min-width: 200px;
		width: 210px;
		max-width: 260px;
	}
}
*/
@media (max-width: 769px){

	.fullWindowWidth {
		width: calc(100vw - var(--scrollbar-width))
	}

	.homeBorder{
		border-style: solid;
		border-color: black;
		border-width: 15px;
	}
	.myLink{
		color: black;
		font-size: 20px;
		text-decoration: none;
		text-decoration-line: none;
		font-family: 'Montserrat', sans-serif;
	}

	.cardProduct {
		min-width: calc(42vw + 4px);
		max-width: calc(42vw + 4px);
		width: calc(42vw + 2px);
		min-height: 302px;
		max-height: 302px;
		height: 302px;
		cursor: pointer;
		margin: 4px;
		border: 2px solid black;
		padding: 0px;
		display: flex;
		justify-content: center;
		align-items: center
	}

	.cardImage {
		min-width: 42vw;
		max-width: 42vw;
		width: 42vw;
		min-height: 230px;
		height: 230px;
		max-height: 230px;
		object-fit: cover;
		border-radius: 3px 3px 0px 0px;
		margin: 0px;
		padding: 0px;

	}

	.cardTitle {

		font-size: 12px;
		min-width: 40vw;
		max-width: 01vw;
		width: 40vw;
		height: 2.5em;
		padding-right: 2px;
		margin: 0px;
		overflow: hidden;



	}

	.cardBody {
			border-top: 1px solid black;
		min-width: 42vw;
		max-width: 42vw;
		width: 42vw;
		background-color: papayawhip;
		padding: 8px;
		margin: 0px;
		border-radius: 0px 0px 3px 3px;
	}


}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  height:  100%;
}

.fitHeight { 
  min-height: 100%;
}


#homePicture {
	background-image: url("./pictures/home-1.jpg");
	background-size: cover;
	background-position: 75% -50%;
	height: 100vh;
	
}

#homeBar {
	display: flex;
	background-color: rgba(255, 255, 255, 0.4);
	text-align: center;
}

#M {
	padding-top: 3vh;
	font-size: 100px;
	color: black;
}

#MNavbar {
	color: black;
	font-size: 40px;
	font-family: 'Cardo', serif;
}

.cardo {
	font-family: 'Cardo', serif;
}





.white-bg {
	background-color: rgb(255,255,255);

}

.white-text{
	color: rgb(255,255,255) !important;
}

.peach{
	color: peach;
}

.linkDecor{
	text-decoration: none;
}

.mb-custom {
	margin-bottom: 5px;
}

.loginCard {
	width : 400px;
	max-width: 400px;
	background-color:  rgba(255,255,255,0.8);
}



.cardHighlight:hover {
	background-color: papayawhip;
}

